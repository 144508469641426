<template>
  <div>
    <h1>Thank you</h1>
    <b-button class="btn-success" @click="close">Close</b-button>
  </div>
</template>

<script>
import { mapActions, mapMutations } from "vuex";

import SockJS from "sockjs-client";
import Stomp from "webstomp-client";

export default {
  mounted() {
    this.send({ msg: "DONE" });
  },
  methods: {
    ...mapActions({
      disconnect: "disconnect",
      send: "send",
    }),
    ...mapMutations({
      SET_CONNECTED: "SET_CONNECTED",
    }),
    close() {
      this.$router.push({ name: "HOME" });
      setTimeout(() => {
        this.disconnect();
      }, 1500);
    },
  },
};
</script>

<style scoped>
h1 {
  font-size: 150px;
  text-align: center;
}

div {
  display: flex;
  flex-direction: column;
  align-content: center;
  justify-content: center;
}
</style>
